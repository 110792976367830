import { ThemeProvider } from '@mui/material/styles';
import theme from '../theme.ts';
import Box from '@mui/material/Box';
import React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { Button, Grid, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import BoxOfficeTablet from '../images/work-boxoffice.png';

const styles = {
  padding: theme.spacing(4), // default padding for all screen sizes
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(4), // padding for screens >= 600px
  },
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(0), // padding for screens >= 900px
  },
  [theme.breakpoints.up('lg')]: {
    margin: '0 auto',
    maxWidth: '100%',
  },
};

const WorkBoxOffice = () => {
  return (
    <Box className="App">
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Box sx={styles}>
          <Grid container direction="row">
            <Grid
              item
              xs={12}
              md={4}
              style={{ backgroundColor: 'yellow', padding: '2em' }}
            >
              <Typography variant="h1">Work Box Office</Typography>
              <Typography variant="body1" style={{ marginBottom: '1em' }}>
                Utilized for ticket sales, this system caters to both stationary
                and mobile users, making it versatile for event sales. Ideal for
                dispersing long lines at high volume events efficiently.{' '}
              </Typography>
              <Button
                component={RouterLink}
                to="/work-boxoffice"
                variant="contained"
                color="primary"
              >
                View Screens
              </Button>
            </Grid>
            <Grid
              item
              xs={12}
              md={8}
              style={{
                backgroundColor: 'blue',
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <img
                src={BoxOfficeTablet}
                alt="BoxOfficeTablet"
                style={{ width: '100%' }}
              />
            </Grid>
          </Grid>
        </Box>
      </ThemeProvider>
    </Box>
  );
};

export default WorkBoxOffice;
