import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  palette: {
    primary: {
      main: '#ff0000',
    },
    secondary: {
      main: '#19857b',
    },
    error: {
      main: '#ff0000',
    },
    background: {
      default: '#fff',
    },
  },
  // Add some overrides for Typography
  typography: {
    fontFamily: 'Oswald, sans-serif',

    h1: {
      fontSize: {
        xs: `3rem`, // font size for screens < 600px
        md: `3.5rem`, // font size for screens >= 900px
      },
      fontWeight: 600,
      color: `#000`,
    },
    h2: {
      fontSize: {
        xs: `1.5rem`, // font size for screens < 600px
        md: `2rem`, // font size for screens >= 900px
      },
      fontWeight: 600,
      color: `#000`,
    },
    h3: {
      fontSize: `2rem`,
      fontWeight: 600,
      color: `#000`,
    },
    body1: {
      fontSize: `1.25rem`,
      fontWeight: 400,
      color: `#000`,
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        a: {
          color: '#418cd8', // Change this to the color you want for <a> tags
          textDecoration: 'none', // Remove underline from <a> tags
          '&:hover': {
            color: '#789abc', // Change this to the color you want for hovered <a> tags
            textDecoration: 'underline', // Add underline to hovered <a> tags
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: `1.25rem`,
          fontWeight: 600,
          backgroundColor: '#000', // Change this to the color you want
          '&:hover': {
            backgroundColor: '#789abc', // Change this to the hover color you want
          },
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: '#418cd8', // Change this to the color you want for links
          textDecoration: 'none', // Remove underline from links
          '&:hover': {
            color: '#789abc', // Change this to the color you want for hovered links
            textDecoration: 'underline', // Add underline to hovered links
          },
        },
      },
    },
  },
});

export default theme;
