import React from 'react';
import './App.css';
import { Grid, Typography } from '@mui/material';
import { Box } from '@mui/material';
import profileImage from './images/jjchambers-profile-image.jpg';
import { Stack } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme.ts';
import CssBaseline from '@mui/material/CssBaseline';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import WorkBoxOffice from './pages/WorkBoxOffice.tsx';
// import WorkBoxOffice from './pages/WorkBoxOffice.tsx';
import { useState, useEffect } from 'react';
import TabletAndroidIcon from '@mui/icons-material/TabletAndroid';
import { useIsMobile } from './components/useIsMobile.tsx';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { Helmet } from 'react-helmet';

const styles = {
  [theme.breakpoints.up('sm')]: {
    paddingX: theme.spacing(4), // padding for screens >= 600px
    paddingY: theme.spacing(0),
  },
  [theme.breakpoints.up('md')]: {
    paddingX: theme.spacing(8), // padding for screens >= 900px
    paddingY: theme.spacing(0),
  },
  [theme.breakpoints.up('lg')]: {
    paddingX: theme.spacing(0),
    margin: '0 auto',
    textAlign: 'center',
  },
};

const App = () => {
  const isMobile = useIsMobile();

  // simulate typing effect
  const [typedText, setTypedText] = useState('');

  const typeText = (text: string, delay: number) => {
    let index = 0;

    const type = () => {
      setTypedText(text.slice(0, index));
      if (index < text.length) {
        index++;
        setTimeout(type, delay);
      }
    };

    type();
  };

  useEffect(() => {
    typeText('Crafting seamless experiences for E-commerce platforms', 100); // adjust the delay to suit your needs
  }, []);

  // Get and set the window height
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => {
      setWindowHeight(window.innerHeight);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <Router>
      <Helmet>
        <title>
          JJ Chambers Design - Crafting seamless experiences for E-commerce
          platforms
        </title>
        <meta
          name="description"
          content="Creative app design and development for e-commerce application for Raleigh, Durham, Chapel Hill, the surrounding Triangle Area, and throughout the world"
        />
        <meta
          name="keywords"
          content="ui, ux, design, development, developer, front-end, e-commerce, kiosk, point of sale, pos, shopify, magento, react, typescript, figma,xd"
        />
      </Helmet>
      <Routes>
        <Route path="/work-boxoffice" element={<WorkBoxOffice />} />
        <Route
          path="/"
          element={
            <Box className="App" style={{ backgroundColor: '#fff' }}>
              <ThemeProvider theme={theme}>
                <CssBaseline />
                <Grid container sx={styles}>
                  <Stack
                    direction="column"
                    spacing={2}
                    alignContent="center"
                    justifyContent="center"
                    alignItems="center" // Add this line
                    style={{
                      backgroundColor: '#f1f1f1',
                      height: `${windowHeight}px`,
                      width: '100%',
                    }}
                    textAlign={{ xs: 'center' }}
                    margin={{ xs: '0 auto' }}
                  >
                    <Box
                      sx={{
                        position: 'relative',
                        display: 'inline-flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <TabletAndroidIcon
                        style={{ fontSize: 150 }}
                        sx={{ marginBottom: 4 }}
                      />
                      <Typography
                        variant="caption"
                        component="div"
                        sx={{
                          position: 'absolute',
                          fontSize: 20,
                        }}
                      >
                        UI/UX
                      </Typography>
                    </Box>
                    <Typography variant="h1" style={{ marginBottom: '0' }}>
                      J.J. Chambers Design
                    </Typography>
                    <Typography
                      variant="h2"
                      sx={{
                        marginBottom: '0.25em',
                        fontWeight: 300,
                      }}
                    >
                      {typedText}
                    </Typography>
                    <Stack
                      style={{
                        position: 'absolute',
                        bottom: 0,
                        padding: theme.spacing(8),
                      }}
                    >
                      <a
                        href="https://www.linkedin.com/in/jjchambers80raleigh/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <LinkedInIcon style={{ fontSize: '3rem' }} />
                      </a>
                    </Stack>
                  </Stack>
                  <Stack
                    direction={isMobile ? 'column' : 'row'}
                    spacing={8}
                    alignContent="center"
                    alignItems="flex-start"
                    style={{
                      backgroundColor: '#fff',
                      width: '100%',
                      maxWidth: isMobile ? '100%' : '1400px',
                      margin: '0 auto',
                    }}
                    sx={{
                      padding: {
                        xs: theme.spacing(4), // Apply a padding of 2 units for xs screens
                        sm: theme.spacing(4), // Apply a padding of 4 units for sm screens
                        md: theme.spacing(8), // Apply a padding of 8 units for md screens and up
                      },
                    }}
                  >
                    <Stack
                      style={{
                        padding: theme.spacing(0),
                      }}
                    >
                      <img
                        src={profileImage}
                        alt="Profile"
                        className="Profile-image"
                        style={{
                          borderRadius: '50%',
                          border: '5px solid #000',
                          width: '100%',
                          minWidth: '200px',
                          display: 'block',
                        }}
                      />
                    </Stack>
                    <Stack
                      justifyContent="start"
                      alignContent={'start'}
                      textAlign="left"
                      style={{
                        padding: theme.spacing(0),
                        width: '100%',
                      }}
                    >
                      <Typography
                        variant="h3"
                        style={{ marginBottom: theme.spacing(2) }}
                      >
                        About Me
                      </Typography>
                      <Typography
                        variant="body1"
                        style={{
                          marginBottom: '1em',
                          fontWeight: '300',
                          lineHeight: '2',
                        }}
                      >
                        As a seasoned front-end UX design professional, I
                        specialize in optimizing digital experiences within
                        e-commerce ecosystems across various platforms including
                        web, mobile, point of sale (POS), SaaS, and kiosk
                        environments. With a keen focus on user interaction and
                        engagement, I leverage my expertise to craft intuitive
                        interfaces that drive conversions and enhance user
                        satisfaction. My holistic approach encompasses strategic
                        thinking, user research, and innovative design solutions
                        tailored to meet the unique needs of each platform and
                        target audience. From streamlining checkout processes to
                        creating immersive storefront experiences, I am
                        dedicated to delivering seamless and impactful user
                        experiences that drive business growth and customer
                        loyalty.
                      </Typography>
                      {/* <Button variant="contained">
                        Schedule a Consultation
                      </Button> */}
                    </Stack>
                  </Stack>
                  {/* <Stack
                    direction="column"
                    spacing={2}
                    alignContent="center"
                    alignItems="center"
                    justifyContent="center"
                    style={{
                      backgroundColor: '#f1f1f1',
                      padding: theme.spacing(8),
                      width: '100%',
                    }}
                  >
                    <Stack
                      justifyContent="center"
                      textAlign="center"
                      style={{
                        padding: theme.spacing(0),
                        width: '100%',
                      }}
                    >
                      <Typography
                        variant="h3"
                        style={{ marginBottom: theme.spacing(2) }}
                      >
                        Projects
                      </Typography>
                    </Stack>
                    <Stack
                      direction={isMobile ? 'column' : 'row'}
                      justifyContent="space-between"
                      width={'100%'}
                      style={{ padding: theme.spacing(0) }}
                    >
                      <Stack
                        style={{
                          backgroundColor: 'blue',
                          width: '25%',
                          height: '300px',
                          margin: theme.spacing(2),
                        }}
                      >
                        Box Office
                      </Stack>
                      <Stack
                        style={{
                          backgroundColor: 'blue',
                          width: '25%',
                          height: '300px',
                          margin: theme.spacing(2),
                        }}
                      >
                        Park
                      </Stack>
                      <Stack
                        style={{
                          backgroundColor: 'blue',
                          width: '25%',
                          height: '300px',
                          margin: theme.spacing(2),
                        }}
                      >
                        Octavia
                      </Stack>
                      <Stack
                        style={{
                          backgroundColor: 'blue',
                          width: '25%',
                          height: '300px',
                          margin: theme.spacing(2),
                        }}
                      >
                        Test
                      </Stack>
                    </Stack>
                  </Stack>
                  <Stack
                    direction="column"
                    spacing={2}
                    alignContent="center"
                    alignItems="center"
                    justifyContent="center"
                    style={{
                      backgroundColor: '#fff',
                      padding: theme.spacing(8),
                      width: '100%',
                    }}
                  >
                    <Stack
                      justifyContent="center"
                      textAlign="center"
                      style={{
                        padding: theme.spacing(0),
                        width: '100%',
                      }}
                    >
                      <Typography
                        variant="h3"
                        style={{ marginBottom: theme.spacing(2) }}
                      >
                        Tech
                      </Typography>

        
                    </Stack>
                    <Stack
                      direction={isMobile ? 'column' : 'row'}
                      justifyContent="space-between"
                      width={'100%'}
                      style={{ padding: theme.spacing(0) }}
                    >
                      <Stack
                        style={{
                          backgroundColor: 'green',
                          width: '25%',
                          height: '300px',
                          margin: theme.spacing(2),
                        }}
                      >
                        Adobe XD
                      </Stack>
                      <Stack
                        style={{
                          backgroundColor: 'green',
                          width: '25%',
                          height: '300px',
                          margin: theme.spacing(2),
                        }}
                      >
                        React
                      </Stack>
                      <Stack
                        style={{
                          backgroundColor: 'green',
                          width: '25%',
                          height: '300px',
                          margin: theme.spacing(2),
                        }}
                      >
                        Typescript
                      </Stack>
                      <Stack
                        style={{
                          backgroundColor: 'green',
                          width: '25%',
                          height: '300px',
                          margin: theme.spacing(2),
                        }}
                      >
                        HTML
                      </Stack>
                    </Stack>
                  </Stack> */}

                  <Stack
                    direction="column"
                    spacing={2}
                    alignContent="center"
                    alignItems="center"
                    justifyContent="center"
                    style={{
                      backgroundColor: 'black',
                      color: 'white',
                      padding: theme.spacing(4),
                      width: '100%',
                    }}
                  >
                    <Stack
                      justifyContent="center"
                      textAlign="center"
                      style={{
                        padding: theme.spacing(0),
                        width: '100%',
                      }}
                    ></Stack>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      style={{ padding: theme.spacing(0) }}
                    >
                      <small>
                        &copy; {new Date().getFullYear()} J.J. Chambers Design
                      </small>
                    </Stack>
                  </Stack>
                </Grid>
              </ThemeProvider>
            </Box>
          }
        />
      </Routes>
    </Router>
  );
};
export default App;
